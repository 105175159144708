import './Card.css';

function Card({text, icon}) {

  // for gh commit activity
  // https://docs.github.com/en/rest/commits/commits?apiVersion=2022-11-28

  return (
    <div className="card">
      <div>
        <img src={"./" + icon + ".png"} alt={icon + " Icon"}></img>
      </div>
      <div>
        <h3>{text}</h3>
      </div>
    </div>
  );
}

export default Card;
