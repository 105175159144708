import './App.css';
import Card from './components/Card';
import GitHubCalendar from 'react-github-calendar';

function App() {
  const minimalTheme = {
    light:['hsl(0, 0%, 92%)', 'rebeccapurple'],
    dark: ['hsl(0, 0%, 92%)', 'rebeccapurple']
    // for `dark` the default theme will be used
  };

  return (
    <div className="App">
      <nav>
        <section className="NavLeft">
          <header>Alexander Kjellberg</header>
        </section>
        <section className="NavRight">
          <a href="#about">About</a>
          <a href="#skills">Skills</a>
          <a href="#projects">Projects</a>
          <a href="#contact">Contact</a>
        </section>
      </nav>
      <main>
        <div id="hero" className="hero">
          <header>
            <big>Hi!</big><br></br><br></br> 
            My name is <big>Alexander</big> and 
            i'm currently in my 2nd year of 
            studying Computer Science at 
            The University of Gothenburg. 
          </header>
          <aside>

          </aside>
        </div>
        <div className='feed-container'>
          <div id="skills" className="skills">
            <header>My Skills </header>
            <div className="card-list">
              <Card text="Java" icon="Java" />
              <Card text="React" icon="React" />
              <Card text="Haskell" icon="Haskell"/>
              <Card text="Javascript" icon="Javascript"/>
              <Card text="OOP" icon="OOP"/>
              <Card text="CSS" icon="CSS"/>
              {/*
              <Card text="Javascript" />
              <Card text="OOP" />
              */}
            </div>
          </div>
          <div id="projects" className="projects">
            <header>My Projects</header>
            <div className="card-list">
              insert horizontal list of projects (awfully empty right now)
            </div>
          </div>
          <div className="activities">
            <header>My Activities</header>
            <div className="card-list">
              <GitHubCalendar username="01alekje" theme={minimalTheme} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
